import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form,
  PrimaryButton,
  FieldTextInput,
  FieldSelect,
  FieldCheckbox,
  FieldPhoneNumberInput,
  Button,
} from '../../components';

import css from './SignupForm.module.css';
import Multiselect from 'multiselect-react-dropdown';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import './signUpCustom.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => {
  const [options, setOption] = useState([
    { name: 'Miami', id: 'Miami' },
    { name: 'Fort Lauderdale', id: 'Fort Lauderdale' },
    { name: 'West Palm Beach', id: 'West Palm Beach' },
    { name: 'Naples', id: 'Naples' },
    { name: 'Fresno', id: 'Fresno' },
    { name: 'Atlanta', id: 'Atlanta' },
    { name: 'Austin', id: 'Austin' },
    { name: 'Detroit', id: 'Detroit' },
    { name: 'Dallas/Fort Worth', id: 'Dallas' },
    { name: 'Kansas', id: 'Kansas' },
    { name: 'Orlando', id: 'Orlando' },
    { name: 'Hartford', id: 'Hartford' },
    { name: 'Philadelphia', id: 'Philadelphia' },
    { name: 'Tampa', id: 'Tampa' },
    { name: 'Houston', id: 'Houston' },
    { name: 'Sarasota', id: 'Sarasota' },
    { name: 'Omaha', id: 'Omaha' },
    { name: 'Fredrick', id: 'Fredrick' },
    { name: 'Maryland', id: 'Maryland' },
    { name: 'Knoxville', id: 'Knoxville' },
    { name: 'Richmond', id: 'Richmond' },
    { name: 'Gainesville', id: 'Gainesville' },
    { name: 'Winston Salem', id: 'Winston Salem' },
    { name: 'Greensboro', id: 'Greensboro' },
    { name: 'High Point', id: 'High Point' },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    console.log('menu selection', { selectedList, selectedItem });
    props.setselectedData([selectedItem]);
  };

  const onRemove = (selectedList, removedItem) => {
    props.setselectedData(selectedList);
  };
  const [value, setValue] = useState();
  const [step, setStep] = useState(0);

  return (
    <FinalForm
      {...props}
      onSubmit={e => {
        e['phoneNumber'] = value;
        props.onSubmit(e);
      }}
      render={fieldRenderProps => {
        const {
          rootClassName,
          className,
          formId,
          handleSubmit,
          inProgress,
          invalid,
          intl,
          onOpenTermsOfService,
        } = fieldRenderProps;

        // email
        const emailLabel = intl.formatMessage({
          id: 'SignupForm.emailLabel',
        });
        const emailPlaceholder = intl.formatMessage({
          id: 'SignupForm.emailPlaceholder',
        });
        const emailRequiredMessage = intl.formatMessage({
          id: 'SignupForm.emailRequired',
        });
        const emailRequired = validators.required(emailRequiredMessage);
        const emailInvalidMessage = intl.formatMessage({
          id: 'SignupForm.emailInvalid',
        });
        const emailValid = validators.emailFormatValid(emailInvalidMessage);

        // password
        const passwordLabel = intl.formatMessage({
          id: 'SignupForm.passwordLabel',
        });
        const passwordPlaceholder = intl.formatMessage({
          id: 'SignupForm.passwordPlaceholder',
        });
        const passwordRequiredMessage = intl.formatMessage({
          id: 'SignupForm.passwordRequired',
        });
        const passwordMinLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooShort',
          },
          {
            minLength: validators.PASSWORD_MIN_LENGTH,
          }
        );
        const passwordMaxLengthMessage = intl.formatMessage(
          {
            id: 'SignupForm.passwordTooLong',
          },
          {
            maxLength: validators.PASSWORD_MAX_LENGTH,
          }
        );
        const passwordMinLength = validators.minLength(
          passwordMinLengthMessage,
          validators.PASSWORD_MIN_LENGTH
        );
        const passwordMaxLength = validators.maxLength(
          passwordMaxLengthMessage,
          validators.PASSWORD_MAX_LENGTH
        );
        const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
        const passwordValidators = validators.composeValidators(
          passwordRequired,
          passwordMinLength,
          passwordMaxLength
        );

        // firstName
        const firstNameLabel = intl.formatMessage({
          id: 'SignupForm.firstNameLabel',
        });
        const firstNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.firstNamePlaceholder',
        });
        const firstNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.firstNameRequired',
        });
        const firstNameRequired = validators.required(firstNameRequiredMessage);

        // lastName
        const lastNameLabel = intl.formatMessage({
          id: 'SignupForm.lastNameLabel',
        });
        const lastNamePlaceholder = intl.formatMessage({
          id: 'SignupForm.lastNamePlaceholder',
        });
        const lastNameRequiredMessage = intl.formatMessage({
          id: 'SignupForm.lastNameRequired',
        });
        const lastNameRequired = validators.required(lastNameRequiredMessage);

        const classes = classNames(rootClassName || css.root, className);
        const submitInProgress = inProgress;
        const submitDisabled = invalid || submitInProgress;
        console.log('disable check', { invalid, submitInProgress });
        const handleTermsKeyUp = e => {
          // Allow click action with keyboard like with normal links
          if (e.keyCode === KEY_CODE_ENTER) {
            onOpenTermsOfService();
          }
        };
        const termsLink = (
          <span
            className={css.termsLink}
            onClick={onOpenTermsOfService}
            role="button"
            tabIndex="0"
            onKeyUp={handleTermsKeyUp}
          >
            <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
          </span>
        );

        const phoneLabel = intl.formatMessage({
          id: 'SignupForm.phoneLabel',
        });
        const phonePlaceholder = intl.formatMessage({
          id: 'SignupForm.phonePlaceholder',
        });
        const phoneRequiredMessage = intl.formatMessage({
          id: 'SignupForm.phoneRequired',
        });
        const phoneRequired = validators.required(phoneRequiredMessage);
        const [userType, setuserType] = React.useState('');
        console.log('phone', { value, phoneRequiredMessage });
        const handleChange = event => {
          setuserType(event.target.value);
          props.setselectedUserType(event.target.value);
        };
        const handleStepClick = () => {
          if (step === 0) {
            setStep(1);
          } else {
            setStep(0);
          }
        };
        return (
          <>
            <Form className={`${classes} SignUpStepForm`} onSubmit={handleSubmit}>
              <div className="StepFormMAinDiv">
                {step === 0 && (
                  <>
                    <FieldTextInput
                      className="Email"
                      type="email"
                      id={formId ? `${formId}.email` : 'email'}
                      name="email"
                      autoComplete="email"
                      label={emailLabel}
                      placeholder={emailPlaceholder}
                      validate={validators.composeValidators(emailRequired, emailValid)}
                    />
                    <div className={`${css.name} Maindiv`}>
                      <FieldTextInput
                        className={`${css.firstNameRoot} firstNAme`}
                        type="text"
                        id={formId ? `${formId}.fname` : 'fname'}
                        name="fname"
                        autoComplete="given-name"
                        label={firstNameLabel}
                        placeholder={firstNamePlaceholder}
                        validate={firstNameRequired}
                      />
                      <FieldTextInput
                        className={`${css.lastNameRoot} LastName`}
                        type="text"
                        id={formId ? `${formId}.lname` : 'lname'}
                        name="lname"
                        autoComplete="family-name"
                        label={lastNameLabel}
                        placeholder={lastNamePlaceholder}
                        validate={lastNameRequired}
                      />
                    </div>
                  </>
                )}

                {/* <FieldPhoneNumberInput
                className={css.phone}
                id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                name="phoneNumber"
                label={phoneLabel}
                placeholder={phonePlaceholder}
                validate={phoneRequired}
              /> */}

                {step === 1 && (
                  <>
                    <FormControl className={`${css.formControl} usertype`}>
                      <label>User type</label>
                      <Select value={userType} onChange={handleChange} displayEmpty>
                        <MenuItem disabled value="">
                          <em>Select user type</em>
                        </MenuItem>
                        <MenuItem value={'customer'}>Customer</MenuItem>
                        <MenuItem value={'provider'}>Provider</MenuItem>
                      </Select>
                      <FormHelperText style={{ color: 'red', fontSize: '15px' }}>
                        Required
                      </FormHelperText>
                    </FormControl>

                    <Box className={`${css.phone} Phone`}>
                      <label>{phoneLabel}</label>
                      <PhoneInput
                        placeholder={phonePlaceholder}
                        value={value}
                        error={
                          value
                            ? isValidPhoneNumber(value)
                              ? undefined
                              : 'Invalid phone number'
                            : 'Phone number required'
                        }
                        name="phoneNumber"
                        id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                        defaultCountry="US"
                        rules={{ required: true, validate: phoneRequired() }}
                        onChange={value => {
                          setValue(value);
                        }}
                      />
                      {!value && (
                        <p style={{ color: 'red', fontSize: '15px', margin: 0 }}>
                          {phoneRequiredMessage}
                        </p>
                      )}
                    </Box>

                    {/* <FieldPhoneNumberInput
                      className={`${css.phone} PhoneNumber`}
                      id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                      name="phoneNumber"
                      label={phoneLabel}
                      placeholder={phonePlaceholder}
                      validate={phoneRequired}
                    /> */}

                    <br />
                    <div className="multistep">
                      <label>Select Your City Area</label>
                      <Multiselect
                        options={options} // Options to display in the dropdown
                        selectedValues={props.selectedList} // Preselected value to persist in dropdown
                        onSelect={(selectedList, selectedItem) =>
                          onSelect(selectedList, selectedItem)
                        } // Function will trigger on select event
                        onRemove={(selectedList, removedItem) =>
                          onRemove(selectedList, removedItem)
                        } // Function will trigger on remove event
                        displayValue="name"
                        placeholder="Select Your City Area"
                        name="city" // Property name to display in the dropdown options
                      />
                    </div>

                    <FieldTextInput
                      className={`${css.password} passwodCustom`}
                      type="password"
                      id={formId ? `${formId}.password` : 'password'}
                      name="password"
                      autoComplete="new-password"
                      label={passwordLabel}
                      placeholder={passwordPlaceholder}
                      validate={passwordValidators}
                    />
                  </>
                )}
              </div>
              <Button className="NextPrevious" onClick={() => handleStepClick()}>
                {step == 0 ? 'Next' : 'Previous'}
              </Button>
              {step === 1 && (
                <div className={css.bottomWrapper}>
                  <p className={css.bottomWrapperText}>
                    <span className={css.termsText}>
                      <FormattedMessage
                        id="SignupForm.termsAndConditionsAcceptText"
                        values={{ termsLink }}
                      />
                    </span>
                  </p>
                  <PrimaryButton
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled || !value || !userType}
                  >
                    <FormattedMessage id="SignupForm.signUp" />
                  </PrimaryButton>
                </div>
              )}
            </Form>
          </>
        );
      }}
    />
  );
};

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
