import React, { Component, createRef, useState } from 'react';
import { array, bool, func, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { isAnyFilterActive } from '../../util/search';
import { propTypes } from '../../util/types';
import {
  SearchResultsPanel,
  SearchFiltersMobile,
  SearchFiltersPrimary,
  SearchFiltersSecondary,
  SortBy,
  SelectMultipleFilter,
} from '../../components';

import FilterComponent from './FilterComponent';
import { validFilterParams } from './SearchPage.helpers';

import css from './SearchPage.module.css';
import './ExtraCss.css';
import { useLocation } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
import AdvanceSearch from './AdvanceSearch';
import AdvanceSearchMobile from './AdvanceSearchMobile';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import Sortpopup from './Sortpopup';
import _ from 'lodash';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { createSlug } from '../../util/urlHelpers';
import BottomNavigationLanding from '../LandingPage/BottomNavigationLanding';

// Primary filters have their content in dropdown-popup.
// With this offset we move the dropdown to the left a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

const cleanSearchFromConflictingParams = (searchParams, sortConfig, filterConfig) => {
  // Single out filters that should disable SortBy when an active
  // keyword search sorts the listings according to relevance.
  // In those cases, sort parameter should be removed.
  const sortingFiltersActive = isAnyFilterActive(
    sortConfig.conflictingFilters,
    searchParams,
    filterConfig
  );
  return sortingFiltersActive
    ? { ...searchParams, [sortConfig.queryParamName]: null }
    : searchParams;
};

/**
 * MainPanel contains search results and filters.
 * There are 3 presentational container-components that show filters:
 * SearchfiltersMobile, SearchFiltersPrimary, and SearchFiltersSecondary.
 * The last 2 are for desktop layout.
 */

class MainPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondaryFiltersOpen: false,
      currentQueryParams: props.urlQueryParams,
      selectedList: [],
      listData: [],
      isCityFilterExisting: false,
      showCityFilter: false,
      filterPriceData: null,
      selectedServiceList: [],
      isServiceFilterExisting: false,
      sortSelected: 'sort_rating',
      sortingFilterData: [],
      favouriteList: [],
      isuserLogedin: false,
      favUserDetail: [],
      currentUser: null,
      totalSortingData: {
        sort_rating: [],
        sort_highLow: [],
        sort_lowHigh: [],
        sort_fastestRtime: [],
      },
    };

    this.wrapperRef = createRef(null);
    this.applyFilters = this.applyFilters.bind(this);
    this.cancelFilters = this.cancelFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    this.initialValues = this.initialValues.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);
    this.resetShowingFn = this.resetShowingData.bind(this);
    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);
  }

  // Apply the filters by redirecting to SearchPage with new filters.
  applyFilters() {
    const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
    const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
    const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    this.setState({ currentQueryParams: {} });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { urlQueryParams, history, filterConfig } = this.props;
    const filterQueryParamNames = filterConfig.map(f => f.queryParamNames);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }

  componentDidMount() {
    this.fetchCurrentUser();
  }

  fetchCurrentUser() {
    this.props
      ?.fetchCurrent()
      .then(res => {
        if (res?.id?.uuid) {
          this.setState({
            favouriteList: res?.attributes?.profile?.publicData?.favrate_list,
            isuserLogedin: true,
            currentUser: res,
          });
        }
      })
      .catch(err => console.log(err));
  }

  getAverageTime(times) {
    var count = times.length;
    var timesInSeconds = [];
    // loop through times
    for (var i = 0; i < count; i++) {
      // parse
      var pieces = times[i].split(':');
      var hrs = Number(pieces[0]);
      var mins = Number(pieces[1]);

      // find value in seconds of time
      var totalSecs = hrs * 60 * 60;
      totalSecs += mins * 60;
      // add to array
      timesInSeconds[i] = totalSecs;
    }
    // find average timesInSeconds
    var total = 0;
    for (var j = 0; j < count; j++) {
      total = total + Number(timesInSeconds[j]);
    }
    var avg = Math.round(total / count);
    // turn seconds back into a time
    var avgMins = Math.floor(avg / 60);
    var avgHrs = Math.floor(avgMins / 60);
    avgMins = avgMins - 60 * avgHrs;

    // add leading zeros for seconds, minutes
    avgMins = ('0' + avgMins).slice(-2);
    // your answer
    return String(avgHrs) + String(avgMins);
  }

  resetShowingData() {
    this.setState({
      sortingFilterData: [],
      totalSortingData: {
        ...this.state.totalSortingData,
        sort_rating: [],
      },
    });
  }

  getUpdatedData(dataValues) {
    if (dataValues?.length > 0) {
      this.setState({ totalSortingData: [] });
      const data = [];
      const FastestResponse = [];
      _.map(dataValues, row => {
        data.push({ ...row, ...{ amount: row?.attributes?.price?.amount } });
        let averageTime = row?.attributes?.publicData?.averageTime;
        if (averageTime && averageTime?.length > 0) {
          const time = this.getAverageTime(averageTime);
          FastestResponse.push({ ...row, ...{ fastResponse: +time } });
        }
      });
      if (this.state.sortSelected === 'sort_highLow') {
        this.setState({ sortingFilterData: _.orderBy(data, 'amount', 'desc') });
      } else if (this.state.sortSelected === 'sort_lowHigh') {
        this.setState({ sortingFilterData: _.orderBy(data, 'amount', 'asc') });
      } else if (this.state.sortSelected === 'sort_fastestRtime') {
        this.setState({ sortingFilterData: _.orderBy(FastestResponse, 'fastResponse', 'asc') });
      }
      this.setState({
        totalSortingData: {
          ...this.state.totalSortingData,
          sort_highLow: _.orderBy(data, 'amount', 'desc'),
          sort_lowHigh: _.orderBy(data, 'amount', 'asc'),
          sort_fastestRtime: _.orderBy(FastestResponse, 'fastResponse', 'asc'),
        },
      });

      if (this.state.favouriteList?.length > 0) {
        let favouriteName = [];
        _.forEach(this.state.favouriteList, row => {
          const findData = _.find(dataValues, val => val?.id?.uuid === row);
          if (findData) {
            const id = findData.id.uuid;
            const title = findData?.attributes?.title;
            const slug = createSlug(title);
            const firstImage =
              findData.images && findData.images.length > 0 ? findData.images[0] : null;
            favouriteName = [...favouriteName, { id, image: firstImage, name: title, slug }];
          }
        });
        this.setState({ favUserDetail: favouriteName });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    // this.setState({ listData: this.props.listData })
    this.setState(
      {
        listData: nextProps.listings,
      },
      () => this.getUpdatedData(nextProps.listings)
    );
  }

  initialValues(queryParamNames) {
    // Query parameters that are visible in the URL
    const urlQueryParams = this.props.urlQueryParams;
    // Query parameters that are in state (user might have not yet clicked "Apply")
    const currentQueryParams = this.state.currentQueryParams;

    // Get initial value for a given parameter from state if its there.
    const getInitialValue = paramName => {
      const currentQueryParam = currentQueryParams[paramName];
      const hasQueryParamInState = typeof currentQueryParam !== 'undefined';
      return hasQueryParamInState ? currentQueryParam : urlQueryParams[paramName];
    };

    // Return all the initial values related to given queryParamNames
    // InitialValues for "amenities" filter could be
    // { amenities: "has_any:towel,jacuzzi" }
    const isArray = Array.isArray(queryParamNames);
    return isArray
      ? queryParamNames.reduce((acc, paramName) => {
          return { ...acc, [paramName]: getInitialValue(paramName) };
        }, {})
      : {};
  }

  getHandleChangedValueFn(useHistoryPush) {
    this.setState({
      filterPriceData: useHistoryPush?.price,
    });
  }

  handleSortBy(urlParam, values) {
    const { history, urlQueryParams } = this.props;
    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, queryParams));
  }
  SearchbyCity() {
    const { history, urlQueryParams } = this.props;

    const city_parms = this.state.selectedList?.map(citylist => `${citylist.id}`).join(',');
    if (city_parms) {
      history.push(`/s?city=${city_parms}`);
    } else {
      history.push(`/s`);
    }
  }
  handleClickOutside = event => {
    // IF exists the Ref of the wrapped component AND his dom children doesnt have the clicked component
    if (this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      // A props callback for the ClikedClickedOutside
      // this.props.onClickedOutside();
      this.setState({
        showCityFilter: false,
      });
    }
  };

  _sortFunction(value) {
    let selectedData = [];
    this.setState({ sortSelected: value });
    this.setState({ sortingFilterData: this.state.totalSortingData[value] });
  }

  getSortAllData(section, values) {
    console.log('helllllllllll', values);
    if (!_.find(this.state.totalSortingData[section], row => row?.id?.uuid === values?.id?.uuid)) {
      this.setState({
        totalSortingData: {
          ...this.state.totalSortingData,
          sort_rating: _.orderBy(
            [...this.state.totalSortingData?.sort_rating, values],
            'rating',
            'desc'
          ),
        },
      });
    }
    if (
      this.state.sortSelected === 'sort_rating' &&
      !_.find(this.state.sortingFilterData, row => row?.id?.uuid === values?.id?.uuid)
    ) {
      this.setState({
        sortingFilterData: _.orderBy([...this.state.sortingFilterData, values], 'rating', 'desc'),
      });
    }
  }

  render() {
    const {
      className,
      rootClassName,
      urlQueryParams,
      listings,
      searchInProgress,
      searchListingsError,
      searchParamsAreInSync,
      onActivateListing,
      onManageDisableScrolling,
      onOpenModal,
      onCloseModal,
      onMapIconClick,
      pagination,
      searchParamsForPagination,
      showAsModalMaxWidth,
      filterConfig,
      sortConfig,
      currentUser,
    } = this.props;

    const setSelected = data => {
      this.setState({
        selectedList: data,
      });
    };

    const setSelectedService = data => {
      this.setState({
        selectedServiceList: data,
      });
    };
    const customStyles = {
      searchBox: {
        // To change search box element look
        borderBottom: '1px solid var(--marketplaceColor) !important',
        fontSize: '16px',
      },
    };

    // const primaryFilters = filterConfig.filter(f => f.group === 'primary' || f.type === "SelectMultipleFilter");
    const primaryFilters = filterConfig.filter(
      f => f.group === 'primary' || f.type === 'SelectMultipleFilter'
    );

    const secondaryFilters = filterConfig.filter(f => f.group !== 'primary');
    const hasSecondaryFilters = !!(secondaryFilters && secondaryFilters.length > 0);
    // primaryFilters.push('bassist')

    // Selected aka active filters
    const selectedFilters = validFilterParams(urlQueryParams, filterConfig);
    const selectedFiltersCount = Object.keys(selectedFilters).length;

    // Selected aka active secondary filters
    const selectedSecondaryFilters = hasSecondaryFilters
      ? validFilterParams(urlQueryParams, secondaryFilters)
      : {};
    const selectedSecondaryFiltersCount = Object.keys(selectedSecondaryFilters).length;

    const isSecondaryFiltersOpen = !!hasSecondaryFilters && this.state.isSecondaryFiltersOpen;
    const propsForSecondaryFiltersToggle = hasSecondaryFilters
      ? {
          isSecondaryFiltersOpen: this.state.isSecondaryFiltersOpen,
          toggleSecondaryFiltersOpen: isOpen => {
            this.setState({ isSecondaryFiltersOpen: isOpen });
          },
          selectedSecondaryFiltersCount,
        }
      : {};

    // With time-based availability filtering, pagination is NOT
    // supported. In these cases we get the pagination support info in
    // the response meta object, and we can use the count of listings
    // as the result count.
    //
    // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
    const hasPaginationInfo = !!pagination && !pagination.paginationUnsupported;
    const listingsLength = listings ? listings.length : 0;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo ? pagination.totalItems : listingsLength;

    const listingsAreLoaded = !searchInProgress && searchParamsAreInSync;

    const sortBy = mode => {
      const conflictingFilterActive = isAnyFilterActive(
        sortConfig.conflictingFilters,
        urlQueryParams,
        filterConfig
      );

      const mobileClassesMaybe =
        mode === 'mobile'
          ? {
              rootClassName: css.sortBy,
              menuLabelRootClassName: css.sortByMenuLabel,
            }
          : {};
      return sortConfig.active ? (
        <SortBy
          {...mobileClassesMaybe}
          sort={urlQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          onSelect={this.handleSortBy}
          showAsPopup
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };

    const classes = classNames(rootClassName || css.searchResultContainer, className);

    const FilterServices = value => {
      // const { history } = this.props;
      let selected_service = this.props?.urlQueryParams?.pub_yogaStyles?.split(',');

      const { history, urlQueryParams, sortConfig, filterConfig } = this.props;
      const searchParams = { ...urlQueryParams, ...this.state.currentQueryParams };
      const search = cleanSearchFromConflictingParams(searchParams, sortConfig, filterConfig);

      if (selected_service?.some(item => item === value) == false) {
        selected_service.push(value);
        selected_service = selected_service?.map(service => `${service}`).join(',');

        // history.push(`s?pub_yogaStyles=${selected_service}`);
      } else if (selected_service?.some(item => item === value) == true) {
        const index = selected_service?.indexOf(value);
        if (index > -1) {
          selected_service.splice(index, 1);
        }
        selected_service = selected_service?.map(service => `${service}`).join(',');
      } else {
        selected_service = value;
        // history.push(`s?pub_yogaStyles=${value}`);
      }
      search['pub_yogaStyles'] = selected_service;
      history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, search));
    };

    let selected_service = this.props?.urlQueryParams?.pub_yogaStyles?.split(',');

    const options = [
      { name: 'Miami', id: 'Miami' },
      { name: 'Fort Lauderdale', id: 'Fort Lauderdale' },
      { name: 'West Palm Beach', id: 'West Palm Beach' },
      { name: 'Naples', id: 'Naples' },
      { name: 'Fresno', id: 'Fresno' },
      { name: 'Atlanta', id: 'Atlanta' },
      { name: 'Austin', id: 'Austin' },
      { name: 'Detroit', id: 'Detroit' },
      { name: 'Dallas/Fort Worth', id: 'Dallas' },
      { name: 'Kansas', id: 'Kansas' },
      { name: 'Orlando', id: 'Orlando' },
      { name: 'Hartford', id: 'Hartford' },
      { name: 'Philadelphia', id: 'Philadelphia' },
      { name: 'Tampa', id: 'Tampa' },
      { name: 'Houston', id: 'Houston' },
      { name: 'Sarasota', id: 'Sarasota' },
      { name: 'Omaha', id: 'Omaha' },
      { name: 'Fredrick', id: 'Fredrick' },
      { name: 'Maryland', id: 'Maryland' },
      { name: 'Knoxville', id: 'Knoxville' },
      { name: 'Richmond', id: 'Richmond' },
      { name: 'Gainesville', id: 'Gainesville' },
      { name: 'Winston Salem', id: 'Winston Salem' },
      { name: 'Greensboro', id: 'Greensboro' },
      { name: 'High Point', id: 'High Point' },
    ];

    const onSelect = (selectedList, selectedItem) => {
      this.setState({
        selectedList: selectedList,
      });
    };

    const onRemove = (selectedList, removedItem) => {
      this.setState({
        selectedList: selectedList,
      });
    };

    let selected_city = this.props?.urlQueryParams?.keywords?.split(',');
    if (this.state.isCityFilterExisting === false && selected_city?.length > 0) {
      let defaultseletedCity = [];
      this.setState({
        isCityFilterExisting: true,
      });

      selected_city.map(city => {
        if (city != '') {
          defaultseletedCity.push({ name: city, id: city });
        }
      });
      this.setState({
        selectedList: defaultseletedCity,
      });
    }
    const serviceRequestArray = {
      worship_leader: 'Worship Leader',
      drummer: 'Drummer',
      bassist: 'Bassist',
      pianist: 'Keys',
      acoustic_guitar: 'Acoustic Guitar',
      electric_guitar: 'Electric Guitar',
      vocalist: 'Vocalist',
      tech: 'Tech',
      special_events: 'Special events',
      organist: 'Organist',
    };

    let selected_service_list = this.props?.urlQueryParams?.pub_yogaStyles?.split(',');
    if (this.state.isServiceFilterExisting === false && selected_service_list?.length > 0) {
      let defaultseletedService = [];
      this.setState({
        isServiceFilterExisting: true,
      });

      selected_service_list.map(service => {
        if (service != '') {
          defaultseletedService.push({ name: service, id: serviceRequestArray[service] });
        }
      });
      this.setState({
        selectedServiceList: defaultseletedService,
      });
    }
    const handleSubmit = values => {
      const { currentSearchParams } = this.props;

      // const keywords = this.state.selectedServiceList.map(({ id, name }) => `${name}`).join(',') !== "" ?
      //   this.state.selectedServiceList.map(({ name, id }) => `${name}`).join(',') :
      //   null;

      const daterange = values.daterange;
      const cityvalues =
        this.state.selectedList?.map(({ name, id }) => `${id}`).join(',') !== ''
          ? this.state.selectedList.map(({ name, id }) => `${id}`).join(',')
          : null;
      const { history } = this.props;
      if (values.isClear === true) {
        const searchParams = {
          pub_yogaStyles: null,
          dates: null,
          city: null,
          price: null,
        };
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
        // window.location.reload();
        return;
      }
      if (values.location !== '') {
        const { search, selectedPlace } = values.location;

        const { origin, bounds } = selectedPlace;

        const originMaybe = config.sortSearchByDistance ? { origin } : {};
        const searchParams = {
          ...currentSearchParams,
          ...originMaybe,

          address: search,
          bounds,

          pub_yogaStyles: values.keywords,
          dates: daterange,
          city: cityvalues,
          price: this.state.filterPriceData,
        };
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
      } else {
        const searchParams = {
          pub_yogaStyles: values.keywords,
          dates: daterange,
          city: cityvalues,
          price: this.state.filterPriceData,
        };
        history.push(
          createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams)
        );
      }

      // const searchParams = {
      //   ...currentSearchParams,
      //   ...originMaybe,
      //   address: search,
      //   pub_yogaStyles: keywords,
      //   bounds,
      // };

      // s?pub_yogaStyles=drummer%2Celectric_guitar

      // history.push(`/s?pub_yogaStyles=${keywords}`);
    };

    return (
      <div className={`${classes} custom_class_main_sec`} onClick={e => this.handleClickOutside(e)}>
        {/* <SearchFiltersPrimary
          className={css.searchFiltersPrimary}
          sortByComponent={sortBy('desktop')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          {...propsForSecondaryFiltersToggle}
        >
          {primaryFilters.map(config => {
            if (config.type !== "SelectMultipleFilter") {
              return (
                <FilterComponent
                  key={`SearchFiltersPrimary.${config.id}`}
                  idPrefix="SearchFiltersPrimary"
                  filterConfig={config}
                  urlQueryParams={urlQueryParams}
                  initialValues={this.initialValues}
                  getHandleChangedValueFn={this.getHandleChangedValueFn}
                  showAsPopup
                  contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
                />
              );
            }
            else {
              return null
            }

          })}
          <div className="OutsideClickHandler_root__1ovUd" key="test01">
            <div className="FilterPopup_root__1Xfkn_button">
              <button className={`${selected_city?.length > 0 && selected_city[0] !== "" ? "servicesstyle" : "FilterPopup_root__1Xfkn1"} `}
                onClick={() => {
                  this.setState({
                    showCityFilter: !this.state.showCityFilter
                  })
                }}
              >City</button>
              <div className="FilterPopup_popup__2Ilcr" >
              </div>
            </div>
          </div>
          {this.state.showCityFilter &&
            <div className="selctcity" ref={this.wrapperRef} >
              <Multiselect
                options={options}
                id="makegsn"
                selectedValues={this.state.selectedList} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem) => {
                  onSelect(selectedList, selectedItem);
                }} // Function will trigger on select event
                onRemove={(selectedList, removedItem) => onRemove(selectedList, removedItem)} // Function will trigger on remove event
                displayValue="name"
                closeOnSelect={true}
                style={customStyles}
                placeholder="Select Your City Area"
                className={`test012 ${css.makegsn_input}`}
                name='city' // Property name to display in the dropdown options
              />
              <div className="action_container">
                <button className="button" onClick={() => {
                  this.setState({
                    showCityFilter: !this.state.showCityFilter
                  })
                }}>Cancel</button>
                <button className="button" onClick={() => {
                  this.SearchbyCity();
                  this.setState({
                    showCityFilter: !this.state.showCityFilter
                  })
                }}>Apply</button>
              </div>
            </div>
          }

          {primaryFilters[3].config.options.map(servic => {
            return <div className="OutsideClickHandler_root__1ovUd" key={servic.label}>
              <div className="FilterPopup_root__1Xfkn">
                <button className={`${selected_service?.some(item => item === servic.key) ? css.servicesstyle : 'FilterPopup_label__2IYqC'}`} onClick={() => { FilterServices(servic.key) }}>{servic.label}</button>
                <div className="FilterPopup_popup__2Ilcr" >
                </div>
              </div>
            </div>
          })}

        </SearchFiltersPrimary> */}
        {/* {!isMobile ? ( */}
        {/* <div className="advanceSearch"> <AdvanceSearch
          //     onSubmit={handleSubmit}
          //     setSelected={setSelected}
          //     selectedList={this.state.selectedList}
          //     selectedServiceList={this.state.selectedServiceList}
          //     setSelectedService={setSelectedService}
          //     primaryFilters={primaryFilters}
          //     urlQueryParams={urlQueryParams}
          //     initialValues={this.initialValues}
          //     getHandleChangedValueFn={this.getHandleChangedValueFn}
          //     FILTER_DROPDOWN_OFFSET={FILTER_DROPDOWN_OFFSET}
          //   />
          // </div>
        // ) : null
          // <div className='advanceSearch' style={{ flex: "0 0 0" }}>
          //   <AdvanceSearchMobile
          //     onSubmit={handleSubmit}
          //     setSelected={setSelected}
          //     selectedList={this.state.selectedList}
          //     selectedServiceList={this.state.selectedServiceList}
          //     setSelectedService={setSelectedService}
          //     primaryFilters={primaryFilters}
          //     urlQueryParams={urlQueryParams}
          //     initialValues={this.initialValues}
          //     getHandleChangedValueFn={this.getHandleChangedValueFn}
          //     FILTER_DROPDOWN_OFFSET={FILTER_DROPDOWN_OFFSET}
          //   />
          // </div>
        }
        {/* <SearchFiltersMobile
          className={css.searchFiltersMobile}
          urlQueryParams={urlQueryParams}
          sortByComponent={sortBy('mobile')}
          listingsAreLoaded={listingsAreLoaded}
          resultsCount={totalItems}
          searchInProgress={searchInProgress}
          searchListingsError={searchListingsError}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onMapIconClick={onMapIconClick}
          onManageDisableScrolling={onManageDisableScrolling}
          onOpenModal={onOpenModal}
          onCloseModal={onCloseModal}
          resetAll={this.resetAll}
          selectedFiltersCount={selectedFiltersCount}
        >
          {filterConfig.map(config => {
            return (
              <FilterComponent
                key={`SearchFiltersMobile.${config.id}`}
                idPrefix="SearchFiltersMobile"
                filterConfig={config}
                urlQueryParams={urlQueryParams}
                initialValues={this.initialValues}
                getHandleChangedValueFn={this.getHandleChangedValueFn}
                liveEdit
                showAsPopup={true}
              />
            );
          })}

        </SearchFiltersMobile> */}

        {/* {isSecondaryFiltersOpen ? (
          <div className={classNames(css.searchFiltersPanel)}>
            <SearchFiltersSecondary
              urlQueryParams={urlQueryParams}
              listingsAreLoaded={listingsAreLoaded}
              applyFilters={this.applyFilters}
              cancelFilters={this.cancelFilters}
              resetAll={this.resetAll}
              onClosePanel={() => this.setState({ isSecondaryFiltersOpen: false })}
            >
              {secondaryFilters.map(config => {
                return (
                  <FilterComponent
                    key={`SearchFiltersSecondary.${config.id}`}
                    idPrefix="SearchFiltersSecondary"
                    filterConfig={config}
                    urlQueryParams={urlQueryParams}
                    initialValues={this.initialValues}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    showAsPopup={true}
                  />
                );
              })}
            </SearchFiltersSecondary>
          </div>
        ) : (
          <div
            className={classNames(css.listings, {
              [css.newSearchInProgress]: !listingsAreLoaded,
            })}
          >
            {searchListingsError ? (
              <h2 className={css.error}>
                <FormattedMessage id="SearchPage.searchError" />
              </h2>
            ) : null} */}
        <div
          className={classNames(css.listings, {
            [css.newSearchInProgress]: !listingsAreLoaded,
          })}
        >
          {/* <div className="sort_component" style={{ marginLeft: 'auto' }}>
            <Sortpopup
              sortSelected={this.state.sortSelected}
              sortFunction={value => this._sortFunction(value)}
            />
          </div> */}
          <SearchResultsPanel
            className={`${css.searchListingsPanel} searchListingsPanel_new`}
            listings={
              this.state.sortingFilterData?.length > 0
                ? this.state.sortingFilterData
                : this.state.listData
            }
            pagination={listingsAreLoaded ? pagination : null}
            search={searchParamsForPagination}
            setActiveListing={onActivateListing}
            selected_city={selected_city}
            getSortAllData={(section, values) => this.getSortAllData(section, values)}
            totalSortingData={this.state.sortSelected}
            resetShowingData={this.resetShowingFn}
            favUserDetail={this.state.favUserDetail}
            currentUser={this.state.currentUser}
            fetchCurrentUser={() => this.fetchCurrentUser()}
            isuserLogedin={this.state.isuserLogedin}
          />
        </div>
        {isMobile && <BottomNavigationLanding />}
      </div>
    );
  }
}

MainPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listings: [],
  resultsCount: 0,
  pagination: null,
  searchParamsForPagination: {},
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
};

MainPanel.propTypes = {
  className: string,
  rootClassName: string,

  urlQueryParams: object.isRequired,
  listings: array,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParamsAreInSync: bool.isRequired,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  onMapIconClick: func.isRequired,
  pagination: propTypes.pagination,
  searchParamsForPagination: object,
  showAsModalMaxWidth: number.isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  fetchCurrent: func,
  history: shape({
    push: func.isRequired,
  }).isRequired,
};
const mapStateToProps = state => {
  const { reviews, fetchReviewsError } = state.ListingPage;
  return { reviews, fetchReviewsError };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  fetchCurrent: () => dispatch(fetchCurrentUser(null, 'return')),
});
export default compose(connect(mapStateToProps, mapDispatchToProps))(MainPanel);
