import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, LocationAutocompleteInput } from '../../components';
import { ensureCurrentUser } from '../../util/data';
import Multiselect from 'multiselect-react-dropdown';

import css from './TopbarSearchFormHome.module.css';
import FilterComponent from '../../containers/SearchPage/FilterComponent';
import { Box } from '@material-ui/core';
// import './TopbarSearchFormHome.module.css';
import moment from 'moment';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import './topbarcustom.css';
const identity = v => v;
const FILTER_DROPDOWN_OFFSET = -14;

class TopbarSearchFormHomeComponent extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.searchInput = React.createRef();
    this.state = { dateRange: '', open: false, singleSelect: false };
  }

  onSubmit(values) {
    const dateFormat = moment(this.state.dateRange).format('yyyy-MM-DD');
    this.props.onSubmit({
      keywords: values.keywords,
      location: values?.location ? values?.location : '',
      city: values?.city ? values?.city : null,

      daterange: dateFormat !== 'Invalid date' ? `${dateFormat},${dateFormat}` : null,
    });
    // blur search input to hide software keyboard
    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  }

  toggleSingleSelect(value) {
    this.setState(prevState => {
      return {
        ...this.state,
        singleSelect: value,
      };
    });
  }

  onChange(location) {
    if (location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      // this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }
  getHandleChangedValueFn(useHistoryPush) {}

  render() {
    // const user = ensureCurrentUser(currentUser);
    return (
      <FinalForm
        {...this.props}
        onSubmit={this.onSubmit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            intl,
            desktopInputRoot,
            isMobile,
            handleSubmit,
          } = formRenderProps;
          const Submit = handleSubmit;
          const classes = classNames(rootClassName, className);
          const [options, setOption] = useState([
            { name: 'Miami', id: 'Miami' },
            { name: 'Fort Lauderdale', id: 'Fort Lauderdale' },
            { name: 'West Palm Beach', id: 'West Palm Beach' },
            { name: 'Naples', id: 'Naples' },
            { name: 'Fresno', id: 'Fresno' },
            { name: 'Atlanta', id: 'Atlanta' },
            { name: 'Austin', id: 'Austin' },
            { name: 'Detroit', id: 'Detroit' },
            { name: 'Dallas/Fort Worth', id: 'Dallas' },
            { name: 'Kansas', id: 'Kansas' },
            { name: 'Orlando', id: 'Orlando' },
            { name: 'Hartford', id: 'Hartford' },
            { name: 'Philadelphia', id: 'Philadelphia' },
            { name: 'Tampa', id: 'Tampa' },
            { name: 'Houston', id: 'Houston' },
            { name: 'Sarasota', id: 'Sarasota' },
            { name: 'Omaha', id: 'Omaha' },
            { name: 'Fredrick', id: 'Fredrick' },
            { name: 'Maryland', id: 'Maryland' },
            { name: 'Knoxville', id: 'Knoxville' },
            { name: 'Richmond', id: 'Richmond' },
            { name: 'Gainesville', id: 'Gainesville' },
            { name: 'Winston Salem', id: 'Winston Salem' },
            { name: 'Greensboro', id: 'Greensboro' },
            { name: 'High Point', id: 'High Point' },
          ]);

          const onSelect = (selectedList, selectedItem) => {
            this.props.setSelected(selectedList);
          };
          const onRemove = (selectedList, removedItem) => {
            this.props.setSelected(selectedList);
          };
          const onSelectService = (selectedList, selectedItem) => {
            this.props.setSelectedService(selectedList);
          };
          const onRemoveService = (selectedList, removedItem) => {
            this.props.setSelectedService(selectedList);
          };

          return (
            <div className="main_container">
              <div className="button_container search-redesign">
                <Form className={`${classes} ${css.customform}`} onSubmit={handleSubmit}>
                  <div className={css.mainsearchdiv}>
                    {/*   <Field
                  name="location"
                  format={identity}
                  render={({ input, meta }) => {
                    const { onChange, ...restInput } = input;

                    // Merge the standard onChange function with custom behaviur. A better solution would
                    // be to use the FormSpy component from Final Form and pass this.onChange to the
                    // onChange prop but that breaks due to insufficient subscription handling.
                    // See: https://github.com/final-form/react-final-form/issues/159
                    const searchOnChange = value => {
                      onChange(value);
                      this.onChange(value);
                    };

                    const searchInput = { ...restInput, onChange: searchOnChange };
                    return (
                      <LocationAutocompleteInput
                        className={isMobile ? `${css.mobileInputRoot} ${css.LocationAutocompleteInput}` : `${desktopInputRootClass} ${css.LocationAutocompleteInput}`}
                        iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
                        inputClassName={isMobile ? css.mobileInput : css.desktopInput}
                        predictionsClassName={
                          isMobile ? css.mobilePredictions : css.desktopPredictions
                        }
                        predictionsAttributionClassName={
                          isMobile ? css.mobilePredictionsAttribution : null
                        }
                        placeholder={intl.formatMessage({ id: 'TopbarSearchForm.placeholder' })}
                        closeOnBlur={!isMobile}
                        inputRef={node => {
                          this.searchInput = node;
                        }}
                        input={searchInput}
                        meta={meta}
                      />
                    );
                  }}
                />
              </div> */}
                    <Box className="main_homepage_section selectBox">
                      <Box className={css.mainservicediv}>
                        <Field
                          name="keywords"
                          render={({ input, meta, pristine, values, submitting }) => {
                            return (
                              <>
                                <div
                                  className={
                                    'w_100 topbar_search_home ' +
                                    css.mainserch +
                                    ' ' +
                                    css.searchButtonAlign +
                                    ' ' +
                                    'selectContainer'
                                  }
                                >
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ opacity: 1, fill: 'none', marginLeft: '5px' }}
                                  >
                                    <path
                                      d="M7 16V4.35537C7 3.87383 7 3.63306 7.0876 3.43778C7.16482 3.26565 7.28917 3.11887 7.44627 3.0144C7.62449 2.89588 7.86198 2.8563 8.33696 2.77714L17.137 1.31047C17.7779 1.20364 18.0984 1.15023 18.3482 1.243C18.5674 1.32441 18.7511 1.48005 18.8674 1.68286C19 1.91398 19 2.23889 19 2.8887V14M7 16C7 17.6568 5.65685 19 4 19C2.34315 19 1 17.6568 1 16C1 14.3431 2.34315 13 4 13C5.65685 13 7 14.3431 7 16ZM19 14C19 15.6568 17.6569 17 16 17C14.3431 17 13 15.6568 13 14C13 12.3431 14.3431 11 16 11C17.6569 11 19 12.3431 19 14Z"
                                      stroke=""
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </svg>

                                  <Field
                                    className={
                                      isMobile
                                        ? `${css.mobileInputRoot} ${css.servicecss}`
                                        : `${css.desktopInputRoot} ${css.servicecss} selectField`
                                    }
                                    name="keywords"
                                    component="select"
                                    style={{ color: '#98A2B3' }}
                                  >
                                    <option>Services</option>

                                    <option value="worship_leader">Worship Leader</option>
                                    <option value="drummer">Drummer</option>
                                    <option value="bassist">Bassist</option>
                                    <option value="pianist">Keys</option>
                                    <option value="acoustic_guitar">Acoustic Guitar</option>
                                    <option value="electric_guitar">Electric Guitar</option>
                                    <option value="vocalist">Vocalist</option>
                                    <option value="tech">Tech</option>
                                    <option value="special_events">Special events</option>
                                    <option value="organist">Organist</option>
                                    <option value="strings">Strings</option>
                                  </Field>
                                  {/* <svg
                                    className={css.selectsvg}
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                  >
                                    <path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                                  </svg> */}
                                </div>
                              </>
                            );
                          }}
                        />
                        {/* <div className={'w_100 custom_home_ser ' + css.mainserch}>
                          <Multiselect
                            className={
                              isMobile
                                ? `${css.mobileInputRoot} ${css.servicecss}`
                                : `${css.desktopInputRoot} ${css.servicecss}`
                            }
                            singleSelect={true}
                            isObject={false}

                            options={serviceOptions} // Options to display in the dropdown
                            selectedValues={
                              this.props?.selectedServiceList?.length > 0
                                ? this.props?.selectedServiceList
                                : []
                              //  user?.attributes?.profile?.publicData.city
                            } // Preselected value to persist in dropdown
                            onSelect={(selectedList, selectedItem) =>
                              onSelectService(selectedList, selectedItem)
                            } // Function will trigger on select event
                            onRemove={(selectedList, removedItem) =>
                              onRemoveService(selectedList, removedItem)} // Function will trigger on remove event
                            displayValue="id"
                            placeholder="Select Services"
                            name="keywords" // Property name to display in the dropdown options
                          />
                          <svg
                            className={css.selectsvg}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                          </svg>
                        </div> */}
                      </Box>
                    </Box>
                  </div>
                  {/* <div className='d_flex'> */}
                  <Box className={css.select_date + ' ' + css.searchButtonAlign + ' dateContainer'}>
                    <svg
                      width="21"
                      height="22"
                      viewBox="0 0 21 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ opacity: 1, fill: 'none' }}
                    >
                      <path
                        d="M19.2 9H1.20001M14.2 1V5M6.20001 1V5M6.00001 21H14.4C16.0802 21 16.9202 21 17.562 20.673C18.1265 20.3854 18.5854 19.9265 18.873 19.362C19.2 18.7202 19.2 17.8802 19.2 16.2V7.8C19.2 6.11984 19.2 5.27976 18.873 4.63803C18.5854 4.07354 18.1265 3.6146 17.562 3.32698C16.9202 3 16.0802 3 14.4 3H6.00001C4.31985 3 3.47978 3 2.83804 3.32698C2.27355 3.6146 1.81461 4.07354 1.52699 4.63803C1.20001 5.27976 1.20001 6.11984 1.20001 7.8V16.2C1.20001 17.8802 1.20001 18.7202 1.52699 19.362C1.81461 19.9265 2.27355 20.3854 2.83804 20.673C3.47978 21 4.31985 21 6.00001 21Z"
                        stroke=""
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid container justifyContent="space-around">
                        <KeyboardDatePicker
                          disablePast={true}
                          cancelLabel={'cancel'}
                          disableToolbar
                          open={this.state.open}
                          onOpen={() => this.setState({ open: true })}
                          variant="inline"
                          onClose={() => this.setState({ open: false })}
                          autoOk
                          format="yyyy-MM-dd"
                          margin="normal"
                          id="date-picker-inline"
                          value={this.state.dateRange != '' ? this.state.dateRange : null}
                          placeholder="Date"
                          className="dateField"
                          onChange={date => {
                            this.setState({ dateRange: date, open: false });
                          }}
                          onClick={() => this.setState({ open: true })}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Box>
                  {/* <Field
                      name="city"
                      render={({ input, meta, pristine, values, submitting }) => {
                        return (
                          <>
                            <div className={css.mainserch}>
                              <Field
                                className={
                                  isMobile
                                    ? `${css.mobileInputRoot} ${css.servicecss}`
                                    : `${css.desktopInputRoot} ${css.servicecss}`
                                }
                                name="city"
                                component="select"
                              >
                                <option>Select City</option>

                                <option value="Miami">Miami</option>
                                <option value="Fort Lauderdale">Fort Lauderdale</option>
                              </Field>
                              <svg
                                className={css.selectsvg}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                              >
                                <path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                              </svg>
                            </div>
                          </>
                        );
                      }}
                    /> */}
                  <div
                    className={
                      `${css.mainserch} custom_home_ser cityContainer` + ' ' + css.searchButtonAlign
                    }
                  >
                    <svg
                      width="19"
                      height="22"
                      viewBox="0 0 19 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ opacity: 1, fill: 'none' }}
                    >
                      <path
                        d="M9.40002 12C11.0569 12 12.4 10.6569 12.4 9C12.4 7.34315 11.0569 6 9.40002 6C7.74317 6 6.40002 7.34315 6.40002 9C6.40002 10.6569 7.74317 12 9.40002 12Z"
                        stroke=""
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.40002 21C13.4 17 17.4 13.4183 17.4 9C17.4 4.58172 13.8183 1 9.40002 1C4.98175 1 1.40002 4.58172 1.40002 9C1.40002 13.4183 5.40002 17 9.40002 21Z"
                        stroke=""
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <Multiselect
                      className={
                        isMobile
                          ? `${css.mobileInputRoot} ${css.servicecss}`
                          : `${css.desktopInputRoot} ${css.servicecss} cityField`
                      }
                      closeOnSelect={true}
                      options={options} // Options to display in the dropdown
                      singleSelect={this.state.singleSelect}
                      selectedValues={
                        this.props?.selectedList.length > 0 ? this.props?.selectedList : []
                        //  user?.attributes?.profile?.publicData.city
                      } // Preselected value to persist in dropdown
                      onSelect={(selectedList, selectedItem) => {
                        onSelect(selectedList, selectedItem);
                        this.toggleSingleSelect(true);
                      }} // Function will trigger on select event
                      onRemove={(selectedList, removedItem) => {
                        onRemove(selectedList, removedItem);
                        this.toggleSingleSelect(false);
                      }} // Function will trigger on remove event
                      displayValue="name"
                      placeholder="Your city area"
                      name="city" // Property name to display in the dropdown options
                    />
                    {/* <svg
                      className={css.selectsvg}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path d="M3.853 54.87C10.47 40.9 24.54 32 40 32H472C487.5 32 501.5 40.9 508.1 54.87C514.8 68.84 512.7 85.37 502.1 97.33L320 320.9V448C320 460.1 313.2 471.2 302.3 476.6C291.5 482 278.5 480.9 268.8 473.6L204.8 425.6C196.7 419.6 192 410.1 192 400V320.9L9.042 97.33C-.745 85.37-2.765 68.84 3.854 54.87L3.853 54.87z" />
                    </svg> */}
                  </div>
                  {/* </div> */}
                  <button
                    type="button"
                    onClick={() => Submit()}
                    className={`${css.heroButton} ${css.heroButton1} ${css.searchButtonAlign}`}
                    // disabled={submitting || pristine}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="transparent"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ opacity: 1, fill: 'none', marginRight: '10px' }}
                    >
                      <path
                        d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
                        stroke="white"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    Search
                  </button>
                </Form>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormHomeComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormHomeComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchFormHome = injectIntl(TopbarSearchFormHomeComponent);

export default TopbarSearchFormHome;
