import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import { Form, LocationAutocompleteInputField, Button, FieldTextInput } from '../../components';

import css from './EditListingLocationForm.module.css';
import { isMobile } from 'react-device-detect';
import Multiselect from 'multiselect-react-dropdown';
import './EditCustom.css';

const identity = v => v;

export const EditListingLocationFormComponent = props => {
  const [selectedList, setSelected] = useState();
  // console.log('selectedList',)
  return (
    <FinalForm
      {...props}
      onSubmit={e => {
        e['city'] = selectedList?.map(list => `${list.id}`).join(',');

        props.onSubmit(e);
      }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          values,
        } = formRenderProps;

        const titleRequiredMessage = intl.formatMessage({ id: 'EditListingLocationForm.address' });
        const addressPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressPlaceholder',
        });
        const addressRequiredMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressRequired',
        });
        const addressNotRecognizedMessage = intl.formatMessage({
          id: 'EditListingLocationForm.addressNotRecognized',
        });

        const optionalText = intl.formatMessage({
          id: 'EditListingLocationForm.optionalText',
        });

        const buildingMessage = intl.formatMessage(
          { id: 'EditListingLocationForm.building' },
          { optionalText: optionalText }
        );
        const buildingPlaceholderMessage = intl.formatMessage({
          id: 'EditListingLocationForm.buildingPlaceholder',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingLocationForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const [options, setOption] = useState([
          { name: 'Miami', id: 'Miami' },
          { name: 'Fort Lauderdale', id: 'Fort Lauderdale' },
          { name: 'West Palm Beach', id: 'West Palm Beach' },
          { name: 'Naples', id: 'Naples' },
          { name: 'Fresno', id: 'Fresno' },
          { name: 'Atlanta', id: 'Atlanta' },
          { name: 'Austin', id: 'Austin' },
          { name: 'Detroit', id: 'Detroit' },
          { name: 'Dallas/Fort Worth', id: 'Dallas' },
          { name: 'Kansas', id: 'Kansas' },
          { name: 'Orlando', id: 'Orlando' },
          { name: 'Hartford', id: 'Hartford' },
          { name: 'Philadelphia', id: 'Philadelphia' },
          { name: 'Tampa', id: 'Tampa' },
          { name: 'Houston', id: 'Houston' },
          { name: 'Sarasota', id: 'Sarasota' },
          { name: 'Omaha', id: 'Omaha' },
          { name: 'Fredrick', id: 'Fredrick' },
          { name: 'Maryland', id: 'Maryland' },
          { name: 'Knoxville', id: 'Knoxville' },
          { name: 'Richmond', id: 'Richmond' },
          { name: 'Gainesville', id: 'Gainesville' },
          { name: 'Winston Salem', id: 'Winston Salem' },
          { name: 'Greensboro', id: 'Greensboro' },
          { name: 'High Point', id: 'High Point' },
        ]);
        const onSelect = (selectedList, selectedItem) => {
          setSelected(selectedList);
        };
        const onRemove = (selectedList, removedItem) => {
          setSelected(selectedList);
        };
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessage}
            {errorMessageShowListing}
            {/* <LocationAutocompleteInputField
            className={css.locationAddress}
            inputClassName={css.locationAutocompleteInput}
            iconClassName={css.locationAutocompleteInputIcon}
            predictionsClassName={css.predictionsRoot}
            validClassName={css.validLocation}
            autoFocus
            name="location"
            label={titleRequiredMessage}
            placeholder={addressPlaceholderMessage}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompleteSearchRequired(addressRequiredMessage),
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
          /> */}

            <div className="d_flex_main">
              <div className="Select_Services">Select City Area</div>
              <Multiselect
                className={
                  isMobile
                    ? `${css.mobileInputRoot} ${css.servicecss}`
                    : `${css.desktopInputRoot} ${css.servicecss}`
                }
                options={options} // Options to display in the dropdown
                selectedValues={props?.selectedList.length > 0 ? props?.selectedList : []} // Preselected value to persist in dropdown
                onSelect={(selectedList, selectedItem) => onSelect(selectedList, selectedItem)} // Function will trigger on select event
                onRemove={(selectedList, removedItem) => onRemove(selectedList, removedItem)} // Function will trigger on remove event
                displayValue="name"
                placeholder="Select Your City Area"
                name="city" // Property name to display in the dropdown options
              />
            </div>

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingLocationFormComponent);
